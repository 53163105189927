import React, { useState } from "react"
import { useLocation } from "@reach/router"
import { parse } from "query-string"
import { Image } from "react-bootstrap"
import { FormattedMessage, navigate, useIntl } from "gatsby-plugin-intl"
import { useStoreState } from "easy-peasy"
import Amplify, { API, Auth } from "aws-amplify"
import Spinner from "../../components/common/Spinner"
import { Alert } from "react-bootstrap"
import CenteredLayout from "../../components/layout/CenteredLayout"
import { path } from "../../config/routes"
import AuthSession from "../../service/authSession"
import { connectorLogoURL } from "../../helpers/assets"
import awsconfig from "../../aws-exports"
import { useCallbackHandler } from "../../helpers/callback"
import HybridStorage from "../../config/hybridStorage";

Amplify.configure({
  ...awsconfig,
  storage: new HybridStorage({
    domain: process.env.NEPAL_DOMAIN,
    expires: 7,
    path: "/",
    sameSite: "lax",
    secure: process.env.NODE_ENV === "production",
  }),
});

const Callback = () => {
  const intl = useIntl()
  const location = useLocation()
  const { code, installation_id: installationId, state } = parse(location.search)
  const currentOrg = useStoreState((state) => state.currentOrg.item)
  const [isError, setIsError] = useState(false)

  const storeCredentials = async () => {
    await API.post("nepal", "/github/callback", {
      body: {
        installationId,
        organizationID: currentOrg.id,
      },
    })
  }

  const existingUserMessage = intl.formatMessage({
    id: "pages.github.callback.existingUserMessage",
    defaultMessage:
      "We already have an account for you. If you need to sign in, you will find temporary credentials in your email inbox.",
  })

  const welcomeMessage = intl.formatMessage({
    id: "pages.github.callback.welcomeMessage",
    defaultMessage: "Welcome to Locale! To sign in the next time, use the temporary password that was sent to you.",
  })

  const createNewUser = async () => {
    const params = {
      queryStringParameters: { code, installation_id: installationId },
    }
    try {
      const res = await Auth.currentCredentials()
      const userAuth = await API.post("nepal", "/github/signup", params)
      await AuthSession.call(userAuth)
      navigate(redirectURL, {
        replace: true,
        state: {
          toast: {
            message: welcomeMessage,
            type: "success",
          },
        },
      })
    } catch (error) {
      if (error.response?.status === 422) {
        navigate(path("SIGN_IN"), {
          replace: true,
          state: {
            toast: {
              message: existingUserMessage,
              type: "warning",
            },
          },
        })
        return
      }
      if (error.response?.status === 401) {
        setIsError(true)
      }
      navigate(path("SIGN_UP"), { replace: true })
    }
  }

  const { redirectURL, connector } = useCallbackHandler(state, storeCredentials, "github", {
    onUnauthenticated: createNewUser,
  })

  return (connector) ? (
    <CenteredLayout>
      {isError && (
        <Alert variant="danger">
          <FormattedMessage
            id="containers.github.callback.alert"
            defaultMessage="Whoops! You're not authorized. Please re-install the {connectorName} application."
            description="modal error alert"
            values={{ connectorName: connector?.name }}
          />
        </Alert>
      )}
      <h6 className="text-uppercase text-muted mb-4">
        <FormattedMessage
          id="containers.github.callback.successHeader"
          defaultMessage="Huzah!"
          description="{connectorName} callback success header"
          values={{ connectorName: connector?.name }}
        />
      </h6>
      <h1 className="display-4 mb-3">
        <Image
          className="connector-card--image mb-3"
          src={(connector?.logo) ? connector?.logo : connectorLogoURL()}
          onError={e => {
            if (e.target.src !== connectorLogoURL())
              e.target.src = connectorLogoURL()
          }}
        />
        <br />
        <FormattedMessage
          id="containers.github.callback.githubConnectedHeader"
          defaultMessage="{connectorName} connected"
          description="{connectorName} callback connected header"
          values={{ connectorName: connector?.name }}
        />{" "}
        <span role="img" aria-label="sparkles">
          ✨
        </span>
      </h1>
      <p className="text-muted mb-4">
        <FormattedMessage
          id="containers.github.callback.syncingParagraph"
          defaultMessage={`Let's sync your {connectorName} data…`}
          description="{connectorName} callback syncing your data paragraph"
          values={{ connectorName: connector?.name }}
        />
      </p>
      {!isError && <Spinner />}
    </CenteredLayout>
  )
  : null
}

export default Callback
