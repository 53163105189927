import { Auth } from "aws-amplify"
import { StorageHelper } from "@aws-amplify/core"
import AmplifyConfig from "../aws-exports"

export default class AuthSession {
  static async call(authData) {
    const storage = new StorageHelper().getStorage()
    const { AccessToken, IdToken, RefreshToken, sub } = authData
    const cognitoClientID = AmplifyConfig.aws_user_pools_web_client_id
    const baseKey = `CognitoIdentityServiceProvider.${cognitoClientID}.${sub}`
    storage.setItem(
      `CognitoIdentityServiceProvider.${cognitoClientID}.LastAuthUser`,
      sub
    )
    storage.setItem(`${baseKey}.idToken`, IdToken)
    storage.setItem(`${baseKey}.accessToken`, AccessToken)
    storage.setItem(`${baseKey}.refreshToken`, RefreshToken)
    try {
      await Auth.currentSession()
    } catch (e) {
      console.error("AUTH CURRENT SESSION ERROR", e);
    }
  }
}
